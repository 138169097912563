

.example-container {
  width: 100%;
  /* width: 100vw; */
  height: 90vh;
  
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  /* overflow-x: hidden; */

   @media (max-width: 620px) {
    height: 800px;
  }
  
}

.image-carousel-container {
  width: 100%;
  /* width: 100vw; */
  height: 60vh;
  
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  /* overflow-x: hidden; */

   @media (max-width: 620px) {
    height: 40vh;
  }
  
}

.next,
.prev {
  top: calc(50% - 20px);
  position: absolute;
  /* background: white;
  border-radius: 30px; */
  /* width: 40px;
  height: 40px; */
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 18px;
  z-index: 2;
}

.next {
  right: -35px;
}

.prev {
  left: -35px;
}

img {
  /* position: absolute; */
  max-width: 100vw;
}

.refresh {
  padding: 10px;
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  width: 20px;
  height: 20px;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
